import React from "react"
import Page from "../components/page.js"

export default () => {
  return (
    <Page>
      <div>Neredeyiz?</div>
    </Page>
  )
}
