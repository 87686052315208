import React, { Component } from "react"
import Navigation from "./navigation.js"

export default class Page extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          minHeight: "100%",
          padding: "0 0 1em 0",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Navigation></Navigation>
        <div
          style={{
            flexGrow: "2",
            display: "flex",
            width: "85%",
            minHeight: "100%",
            justifyContent: "space-around",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "column nowrap",
              justifyContent: "center",
              alignItems: "start",
              alignContent: "center",
              width: "100%",
            }}
            className="list"
          >
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}
