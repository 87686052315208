import React from "react"
import { Link } from "gatsby"
import "../styles/global.css"

const Navigation = () => {
  return (
    <div style={{
      width:'85%',
      display:'flex',
      flexFlow: 'row nowrap',
      justifyContent:'space-evenly',
      margin:'1em',
    }} className='highlight'>
      <div>Bora Erden</div>
      <Link to='/'><div>Bio</div></Link>
      <Link to='/work'><div>Work</div></Link>
      <Link to='/speaking'><div>Speaking</div></Link>
      <Link to='/contact'><div>Contact</div></Link>
    </div>
  )
}

export default Navigation